/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-10",
    versionChannel: "nightly",
    buildDate: "2023-04-10T00:19:26.594Z",
    commitHash: "81d907b9f0a3f4eae46e55d9abfa0f46796874e6",
};
